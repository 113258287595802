import React, { useState, useMemo, useEffect } from 'react'
import { Typewriter } from 'react-typewriting-effect'
import 'react-typewriting-effect/dist/index.css'
import Grid from '@mui/material/Grid'
import Flash from 'react-reveal/Flash'
import './Dice.css'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { coinToss, changeName } from '../modules/coin'
import Box from '@mui/material/Box';


const Dice = (props) => {
  console.log(props)

  //   const handleHeadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     props.changeName({
  //       ...props.toss,
  //       headname: event.target.value
  //     })
  //   };
  useEffect(() => {
    function rollDice() {
        const dice = [...document.querySelectorAll(".die-list")];
        dice.forEach(die => {
          toggleClasses(die);
          die.dataset.roll = getRandomNumber(1, 6);
        });
      }
      
      function toggleClasses(die) {
        die.classList.toggle("odd-roll");
        die.classList.toggle("even-roll");
      }
      
      function getRandomNumber(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      
      document.getElementById("roll-button").addEventListener("click", rollDice);
    
  }, []);
  

  return (
    <Grid container spacing={2}>
        <Grid
        container 
        item>
      <div class="dice">
      <ol class="die-list odd-roll" data-roll="1" id="die-1">
        <li class="die-item" data-side="1">
          <span class="dot"></span>
        </li>
        <li class="die-item" data-side="2">
          <span class="dot"></span>
          <span class="dot"></span>
        </li>
        <li class="die-item" data-side="3">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </li>
        <li class="die-item" data-side="4">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </li>
        <li class="die-item" data-side="5">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </li>
        <li class="die-item" data-side="6">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </li>
      </ol>
      
    </div>
    </Grid>
    <Grid item><Button type="button" id="roll-button">Roll Dice</Button></Grid>
    
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  toss: state.coin.toss,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      coinToss,
      changeName,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Dice)
