export const COIN = 'coin/COIN'

const initialState = {
  toss: {
    result: '',
    nader: 'nader',
    headname: "Head",
    tailname: "Tail"
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case COIN:
      return {toss: action.payload}
    default:
      return state
  }
}

export const coinToss = (data) => {
  return dispatch => {
    dispatch({
      type: COIN,
      payload: data
    })
  }
}

export const changeName = (data) => {
    return dispatch => {
      dispatch({
        type: COIN,
        payload: data
      })
    }
  }