import React, { useState, useMemo, useEffect } from 'react'
import { Typewriter } from 'react-typewriting-effect'
import 'react-typewriting-effect/dist/index.css'
import Grid from '@mui/material/Grid'
import Flash from 'react-reveal/Flash'
import './Coin.css'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { coinToss, changeName } from '../modules/coin'

const Coin = (props) => {
  console.log(props)
  // const [toss, setToss] = useState({
  //   result: '',
  //   nader: 'nader',
  // })

  const [head, setHead] = useState("Head")
  const [tail, setTail] = useState("Tail")
  const handleHeadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setHead(event.target.value);
    props.changeName({
      ...props.toss,
      headname: event.target.value
    })
  };
  const handleTailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.changeName({
      ...props.toss,
      tailname: event.target.value
    })
  };

  const coinToss = () => {
    if (Math.random() < 0.5) {
      // props.coinToss({ result: 'heads', nader: '' })
      props.coinToss({
        ...props.toss,
        result: 'heads'
      })
      console.log('heads')
    } else {
      // props.coinToss({ result: 'tails', nader: '' })
      props.coinToss({
        ...props.toss,
        result: 'tails'
      })
      console.log('tails')
    }
  }


  return (
    <Grid container spacing={2}>
      <Grid
        item
        container
        direction="column"
        display="flex"
        justify="center"
        style={{"max-width": "414px"}}
        xs={12}>
        <div id="coin" className={props.toss.result} key={+new Date()} onClick={coinToss}>
          <div class="side-a">
          </div>
          <div class="side-b">
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
      <Typography variant="h2" gutterBottom>
      {props.toss.result == "heads" ? props.toss.headname : props.toss.tailname}

      </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField id="Head" value={props.toss.headname} onChange={handleHeadChange}/>
      </Grid>

      <Grid item xs={6}>
        <TextField id="Tail" value={props.toss.tailname} onChange={handleTailChange}/>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
    toss: state.coin.toss
  })
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        coinToss,
        changeName
      },
      dispatch
    )
  
export default connect(mapStateToProps, mapDispatchToProps)(Coin)