import React, { useState, useMemo, useEffect } from 'react'
import { Typewriter } from 'react-typewriting-effect'
import 'react-typewriting-effect/dist/index.css'
import Grid from '@mui/material/Grid'
import Flash from 'react-reveal/Flash'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { coinToss, changeName } from '../modules/coin'

const Multiple = (props) => {
  console.log(props)


  return (
    <Grid container spacing={2}>
      <Grid
        item
        container
        direction="column"
        display="flex"
        justify="center"
        style={{"max-width": "414px"}}
        xs={12}>
      </Grid>
      <Grid item xs={12}>
      <Typography variant="h2" gutterBottom>

      </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField />
      </Grid>

      <Grid item xs={6}>
        <TextField />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
    toss: state.coin.toss
  })
  
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        coinToss,
        changeName
      },
      dispatch
    )
  
export default connect(mapStateToProps, mapDispatchToProps)(Multiple)